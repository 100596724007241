import Image from 'next/image'
import Link from 'next/link'

import linkResolver from '@lib/prismic/link-resolver'

import RichTextSerializer from '@components/RichTextSerializer'

interface Props {
	slice: any
}

export default function SplitWithBanner({ slice }: Props) {
	const { primary } = slice

	return (
		<section className="relative flex flex-col justify-between overflow-hidden section min-h-[480px] md:min-h-[500px] xl:min-h-[944px] box-content">
			{/* Gray background */}
			<div
				className="absolute top-0 bottom-[38%] sm:bottom-1/3 md:bottom-0 w-[108px] xs:w-[200px] sm:w-[275px] md:w-1/3 xl:w-[576px] right-0 bg-gray-150 -z-10"
				aria-hidden="true"
			/>

			<div className="relative pb-10 md:pb-16">
				{/* Colored banner */}
				<div
					className="absolute bottom-0 left-0 top-1/2 md:top-0 right-14 xs:right-1/4 bg-bbx-glow -z-10"
					aria-hidden="true"
				/>

				<div className="container flex max-md:flex-col gap-y-10 xl:relative md:pt-36">
					{/* Banner title */}
					{primary?.banner_title?.[0]?.text && (
						<h2 className="title text-4xl max-w-[360px] font-medium text-white md:text-5xl lg:text-7xl">
							{primary.banner_title[0].text}
						</h2>
					)}

					{/* Image */}
					{primary?.section_image?.url && (
						<div className="flex md:absolute -z-10 top-20 right-0 justify-end max-lg:ml-auto h-[400px] md:h-[500px] xl:h-[904px] max-lg:order-first max-sm:translate-x-4">
							<Image
								src={primary.section_image.url}
								alt={primary.section_image.alt}
								width={primary.section_image.dimensions?.width}
								height={primary.section_image.dimensions?.height}
								quality={100}
								objectFit="contain"
								className="max-lg:object-right-bottom"
							/>
						</div>
					)}
				</div>
			</div>

			<div className="container grid h-full mt-20 lg:grid-cols-2">
				<div className="flex flex-col gap-y-16 lg:gap-y-36 max-w-[240px] lg:max-w-[380px]">
					<div className="flex flex-col">
						{/* Section title */}
						{primary?.section_title?.[0]?.text && (
							<h3 className="text-2xl font-medium md:text-3xl">
								{primary.section_title[0].text}
							</h3>
						)}

						{/* Section description */}
						{primary?.section_description?.[0] && (
							<div className="mt-4 text-sm lg:mt-6 lg:text-lg">
								<RichTextSerializer content={primary.section_description} />
							</div>
						)}

						{/* CTA */}
						{primary?.section_cta && (
							<Link href={linkResolver(primary.section_cta)}>
								<a className="mt-10 mr-auto font-medium lg:text-lg lg:mt-14 button button-primary">
									{primary.section_cta_label}
								</a>
							</Link>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}
